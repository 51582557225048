import React, { useState, useLayoutEffect  } from "react";
import '../Css/Donation.css'
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import PopMail from "../Components/PopMail";
import Footer from "../Components/Footer";
import Table from "react-bootstrap/Table";
import { MetaTags } from "react-meta-tags";
const Donation = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
   <>
    {/* ========= SEO ========== */}

    <MetaTags>
        <title>
        Food Donation Annakshetra
        </title>
        <meta
          name="description"
          content="Join us in making a difference through food donation at Shri Jalaram Bapa Annakshetra. Help us nourish lives and spread kindness to those in need."
        />
        
        <meta
          property="og:title"
          content="Food Donation at Shri Jalaram Bapa Annakshetra"
        />
        <meta
          property="og:description"
          content="Join us in making a difference through food donation at Shri Jalaram Bapa Annakshetra. Help us nourish lives and spread kindness to those in need."
        />
        <meta
          name="keywords"
          content="best place to donate food, food donation, food charity, food drive, charities that donate food, charities for feeding the hungry ,charities feeding the poor, charities that give food to the poor, best charities for feeding the poor, feed the poor, giving food to the needy"
        />
        <link rel="canonical" href="https://jrbannakshetra.com/food-donation-annakshetra" />
      </MetaTags>
   {/* ============== Donation Section1 =========== */}
   <div className="donationSection1">
        <div className="donationBanner">
          <div className="donationBanner1">
            <Zoom>
              <h1>“Annam para Brahma swarupam”</h1>
            </Zoom>
            <Fade left>
              <p>Annadhanam refers to the practice of donating food to those in need. </p>
            </Fade>
          </div>
        </div>
      </div>
      {/* ========== Upcoming Festival ======= */}
      <div className="upcoming-fest mt-5">
        <div className="container">
          <div className="upcoming_Header">
            <h4> Upcoming  <span> Rama Navami Anna Daan</span></h4>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-6 col-12">
              <div className="upcoming-desc">
                <div className="upcoming-head">
                  <h3>Rama Navami Anna Daan</h3>
                </div>
                <div className="upcoming-para">
                  <p>
                  Ram Navami is a very famous festival celebrated throughout India and worldwide by all the devotees of Lord Ramchandra. It is celebrated on the Navami tithi of Shukla paksha of Chaitra month every year. Lord Rama is the 7th incarnation in the dashavtaras of Lord Vishnu. He appeared with His three brothers, Bharata, Laxmana and Shatrughna, in tretayuga. They appeared as the sons of King Dasharatha in the Surya dynasty of King Ikshavaku.
                  </p>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="upcoming-img">
                <img src="https://iskconjuhu.s3.ap-south-1.amazonaws.com/Rama-Navami-Anna-Daan-56409c565d2f71.jpeg" alt="rama-navami" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== Donation list ======== */}
      <div className="services mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="donte-title">
                <h4>Donate in Normal Days</h4>
              </div>
              <div className="donation_list">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Meals</th>
                      <th>Donate Amount</th>
                      <th>Donate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="meals">
                        <h5>Donate 25 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 1,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 50 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 2,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 75 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 3,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 100 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 4,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 125 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 5,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 250 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 10,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 500 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 20,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 1000 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 30,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 1250 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 50,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 2500 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 1,00,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td class="meals">
                        <h5>Donation of your choice</h5>
                      </td>
                      <td class="Donate-amount">
                        <input
                          class="form-control"
                          id="name"
                          type="number"
                          autocomplete="name"
                          placeholder="₹ Amount"
                          required=""
                        />
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-md-6">
              <div className="donte-title">
                <h4>Donate in Festival Days</h4>
              </div>
              <div className="donation_list">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Meals</th>
                      <th>Donate Amount</th>
                      <th>Donate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="meals">
                        <h5>Donate 25 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 1,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 50 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 2,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 75 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 3,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 100 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 4,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 125 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 5,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 250 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 10,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 500 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 20,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 1000 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 30,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 1250 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 50,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="meals">
                        <h5>Donate 2500 Meals</h5>
                      </td>
                      <td class="Donate-amount">
                        <h5>₹ 1,00,000</h5>
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td class="meals">
                        <h5>Donation of your choice</h5>
                      </td>
                      <td class="Donate-amount">
                        <input
                          class="form-control"
                          id="name"
                          type="number"
                          autocomplete="name"
                          placeholder="₹ Amount"
                          required=""
                        />
                      </td>
                      <td class="Donates-btn">
                        <a class="btn" href="/contact-us">
                          <Link to="/contact-us">Donate</Link>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopMail />
      <Footer />
   </>
  )
}

export default Donation;