import React, {useState} from "react";
import "../Css/Footer.css";
import { Link } from "react-router-dom";
import { FaFacebookF,FaInstagram,FaGooglePlusG,FaTwitter} from 'react-icons/fa';
import logo from"../img/logo-white.png"
import founder from '../img/founder.jpg'
import ServiceData from "../Components/ServiceData";
import MoDal from "../Components/MoDal";
import Button from "react-bootstrap/Button";


const Footer = () => {
    // =========================== Service View ====================
    const [modalShow, setModalShow] = useState(false);
    const [serviceId, setServiceId] = useState();
  
    const [finalData, setFinalData] = useState([]);
  
    const serviceView = (id, condition) => {
      setModalShow(condition);
      setServiceId(id);
  
      if (id !== "") {
        console.log(id);
        setFinalData(ServiceData.find((data) => data.id === id));
      }
    };
  return (
    <>
      <div class="Footer_Section mt-5">
        <footer class="site-footer">
          <div class="footer-widgets">
            <div class="container-fluid">
              <div class="row">
              <div class="col-sm-6 text-left footer-text">
                  <div class="d-flex">
                    <div class="footer-logo">
                      <img src={logo} alt="ISKCON" />
                    </div>
                    <div class="footer-txt ps-2">
                      <h3>Shri Jalaram Bapa Foundation</h3>
                      <p>
                      Our Foundation is a non-profit organization that collects food donations  and distributes them to those in need.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 text-left footer-text">
                  <div class="d-flex">
                    
                    <div class="footer-founder-txt ps-2">
                      <h3>Shri Raghubar Dutt Tiwari</h3>
                      <p>
                      Raghubar Dutt Tiwari is a spiritual preacher and mentor of Sanatan Dharma, spreading timeless spiritual cults across societies and also rendering medical services for 35 years globally.       </p>
                    </div>
                    <div class="footer-founder">
                      <img src={founder} alt="ISKCON" />
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className="container">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="foot-about">
                    <p>
                    We organize events and programs to raise awareness about food waste and promote sustainable food practices. The work of a food donation foundation is crucial in ensuring that everyone has access to nutritious food, regardless of their socioeconomic status
                    </p>
                    {/* <ul class="d-flex flex-wrap align-items-center">
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaInstagram />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaFacebookF />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaGooglePlusG />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaTwitter />
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <div class="col-lg-2 col-md-4  col-6 mt-5 mt-md-0">
                    <div class="quick-link">
                    <h2>Useful Links</h2>
                    <ul>
                    <li>
                    <a href="/"><Link to="/">Home Page</Link></a>
                    </li>
                    <li>
                      <a href="/about-shri-jalaram-bapa-foundation">   <Link to="/about-shri-jalaram-bapa-foundation">About Us</Link>{" "}</a>
                    </li>
                    <li>
                      <a href="/food-donation"><Link to="/food-donation">Service Page</Link></a>
                    </li>
                    <li>
                      <a href="/event-shri-jalaram-bapa-foundation"><Link to="/event-shri-jalaram-bapa-foundation">Events Page</Link></a>
                    </li>
                    <li>
                    <a href="/gallery-shri-jalaram-bapa-foundation"><Link to="/gallery-shri-jalaram-bapa-foundation">Gallery Page</Link></a>
                    </li>
                    <li>
                    <a href="/contact-us"><Link to="/contact-us">Contact Page</Link></a>
                    </li>
                  </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-6  mt-5 mt-md-0">
                  <div class="foot-latest-news">
                    <h2>Services</h2>
                    <ul>
                    {ServiceData.map((item, index) => {
                      const { id, FooterHead } = item;
                      return (
                        <>
                        
                            <li>
                              <Button
                                className="footer-services"
                                onClick={() => serviceView(id, true)}
                              >
                                {FooterHead}
                              </Button>

                              <MoDal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                id={serviceId}
                                viewData={finalData}
                              />
                            </li>
                          
                        </>
                      );
                    })}
                    </ul>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mt-5 mt-md-0">
                  <div class="foot-contact">
                    <h2>Contact</h2>
                    <ul>
                      <li>
                        <span> <a href="tel:91 70219 40508">+91 70219 40508</a></span>
                      </li>
                      <li>
                        <span> <a href="mailto:jrbannakshetra1968@gmail.com">jrbannakshetra1968@gmail.com</a></span>
                      </li>
                      <li>
                        <span> <a href="/About">The JRB foundation is a non-profit organization that collects food donations from individuals, businesses, and other organizations, and distributes them to those in need.</a></span>
                      </li>
                    </ul>
                    <div class="foot-about">
                    <ul class="d-flex flex-wrap align-items-center">
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaInstagram />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaFacebookF />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaGooglePlusG />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaTwitter />
                        </a>
                      </li>
                    </ul>
                  </div>
                  </div>
                  {/* <div class="subscribe-form">
                    <form class="d-flex flex-wrap align-items-center">
                      <input type="email" placeholder="Your email" />
                      <input type="submit" value="send" />
                    </form>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div class="footer-bar">
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <div class="copy_right">
                    <p class="m-0">Copyright © 2023 All rights reserved JRB Anna Kshetra</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="copy_right">
                    <p class="m-0">
                      Developed by
                      <a href="https://skdm.in/" target="_blank">
                        Shree Krishna Digital Marketing
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
