import React, { useState,useLayoutEffect } from 'react';
import "../Css/Gallery.css";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import PopMail from "../Components/PopMail";
import Footer from "../Components/Footer";
import Tabs from "./Tabs";
import Items from "./Items";
import GalleryData from '../Components/GalleryData.jsx';
import { MetaTags } from 'react-meta-tags';
const Gallery = () => {
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  const [data, setData] = useState(GalleryData)

  // Store Category in CategoryData 
  const categoryData = GalleryData.map((value) => {
    return value.Category
  });
  ////////////

  const tabsData = ["all", ...new Set(categoryData)];


  // for all categoryData set 
  const filterCategory = (Category) => {
    if (Category == "all") {
      setData(GalleryData)
      return;
    }
    const filteredData = GalleryData.filter((value) => {
      return value.Category == Category;
    })

    setData(filteredData);

  }
  return (
    <>
  {/* ========= SEO ========== */}

  <MetaTags>
        <title>
        Gallery | Shri Jalaram Bapa Annakshetra
        </title>
        <meta
          name="description"
          content="Explore our gallery showcasing what we have done for people. Witness the beauty of memorable moments we've captured."
        />

        <meta
          property="og:title"
          content="Gallery | Shri Jalaram Bapa Annakshetra"
        />
        <meta
          property="og:description"
          content="Explore our gallery showcasing what we have done for people. Witness the beauty of memorable moments we've captured."
        />
        <meta
          name="keywords"
          content="best place to donate food, food donation, food charity, food drive, charities that donate food, charities for feeding the hungry ,charities feeding the poor, charities that give food to the poor, best charities for feeding the poor, feed the poor, giving food to the needy"
        />
        <link rel="canonical" href="https://jrbannakshetra.com/gallery-shri-jalaram-bapa-annakshetra" />
      </MetaTags>

      {/* ============== Gallery Section1 =========== */}
      <div className="gallerySection1">
        <div className="galleryBanner">
          <div className="galleryBanner1">
            <Zoom>
              <h1>Gallery  Shri Jalaram Bapa Annakshetra</h1>
            </Zoom>
            <Fade left>
              <p>What we have done for people</p>
            </Fade>
          </div>
        </div>
      </div>

      {/* gallery images */}
      <div className='Gallery-Section-2'>
        <div className='Gallery-Card'>

          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                <Items data={data} />
              </div>
            </div>

          </div>
        </div>

      </div>
      <PopMail />
      <Footer />
    </>
  );
};

export default Gallery;
