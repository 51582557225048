import { useState } from "react";
import "../Css/NavBar.css";
import logo from "../img/logo.png";
import { Nav, Navbar, Container } from "react-bootstrap";
import {
  FaFacebookF,
  FaInstagram,
  FaGooglePlusG,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";
const NavBar = () => {
  // Change Nav color  when scrolling

  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 20) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  return (
    <>
      <header className="site-header">
        {/* <div className="top-header-bar">
          <div className="container">
            <div className="row flex-wrap justify-content-center justify-content-lg-between align-items-lg-center">
              <div className="col-12 col-lg-8 d-none d-md-flex flex-wrap justify-content-center justify-content-lg-start mb-3 mb-lg-0">
                <div className="header-bar-email">
                  MAIL: <a href="mailto:jrbannakshetra1968@gmail.com"> jrbannakshetra1968@gmail.com</a>
                </div>
                <div className="header-bar-text">
                  <p>
                    PHONE: <span>  <a href="tel:91 70219 40508">+91 70219 40508</a></span>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 d-none d-md-flex flex-wrap justify-content-center justify-content-lg-start mb-3 mb-lg-0">
              <div class="nav-social">
                    <ul class="d-flex flex-wrap align-items-center">
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaInstagram />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaFacebookF />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaGooglePlusG />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/">
                          <FaTwitter />
                        </a>
                      </li>
                    </ul>
                  </div>
              </div>
            </div>
          </div>
        </div> */}
        <section className="NavSection">
          <div className="container-fluid">
            <Navbar
              collapseOnSelect
              expand="lg"
              variant="dark"
              className={color ? "NavBar NavBar-bg" : "NavBar"}
            >
              <Container>
                <Navbar.Brand href="/">
                  <img src={logo} alt="" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse
                  id="responsive-navbar-nav"
                  className="justify-content-start"
                >
                  <Nav>
                    <Nav.Link href="/">
                      {" "}
                      <Link to="/">Home</Link>{" "}
                    </Nav.Link>
                    <Nav.Link href="/about-shri-jalaram-bapa-foundation">
                      {" "}
                      <Link to="/about-shri-jalaram-bapa-foundation">About Us</Link>{" "}
                    </Nav.Link>
                    <Nav.Link href="/food-donation">
                      {" "}
                      <Link to="/food-donation">Services</Link>{" "}
                    </Nav.Link>
                    <Nav.Link href="/event-shri-jalaram-bapa-foundation">
                      <Link to="/event-shri-jalaram-bapa-foundation">Events</Link>{" "}
                    </Nav.Link>
                    <Nav.Link href="/gallery-shri-jalaram-bapa-foundation">
                      <Link to="/gallery-shri-jalaram-bapa-foundation">Gallery</Link>{" "}
                    </Nav.Link>
                    <Nav.Link href="/contact-us">
                      <Link to="/contact-us">Contact Us</Link>{" "}
                    </Nav.Link>

                    <div className="navBar-btn">
                      <a href="/food-donation-annakshetra">
                        {" "}
                        <Link to="/food-donation-annakshetra">Donate Now</Link>
                      </a>
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </section>
      </header>
    </>
  );
};

export default NavBar;
