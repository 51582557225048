import React from "react";
import "../Css/VolenterCard.css";
import Zoom from "react-reveal/Zoom";
const VolenterCard = ({ data }) => {
  return (
    <>
      <div className="row">
        {data.map((value) => {
          const { id, Vhead, VDist, Vdesc, VName, VImage} = value;

          return (
            <>
              <Zoom>
                <div className="col-lg-4 col-sm-6 my-3" key={id}>
                  <div class="Vcard ">
                    <div class="card-two">
                      <header>
                        <div class="avatar ">
                        <img src={VImage} />
                        </div>
                      </header>
                      <div class="desc">
                      <h3>{Vdesc}</h3>
                      {/* <h4></h4> */}
                        <h6>{VName}</h6>
                        <h6>{ VDist}</h6>
                        <p>{Vhead}</p>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </Zoom>
            </>
          );
        })}
      </div>
    </>
  );
};

export default VolenterCard;
