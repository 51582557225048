import React, { useState } from "react";
import "../Css/Event.css";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Footer from "../Components/Footer.jsx";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { MetaTags } from "react-meta-tags";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import PopMail from "../Components/PopMail";
import Img2 from '../img/banner/img7.jpeg';
import Img5 from '../img/banner/img5.jpeg';
import Img7 from '../img/festival/pitra2.jpg';
import Img8 from '../img/festival/pitra4.jpg';
import Img9 from '../img/festival/trith2.jpg';
import Img10 from '../img/festival/trith3.jpg';
import Img11 from '../img/festival/trith5.jpg';
import Img13 from '../img/festival/festival2.jpg';
import Img14 from '../img/festival/festival3.jpg';
import Img15 from '../img/festival/festival4.jpg';
import Img16 from '../img/festival/festival5.jpg';
import Img17 from '../img/festival/event1.jpg';
import Img18 from '../img/festival/festival6.jpg';


const Event = () => {

  return (
    <>
      {/* ========= SEO ========== */}

      <MetaTags>
        <title>
        Events in Shri Jalaram Bapa Annakshetra
        </title>
        <meta
          name="description"
          content="Join us at Shri Jalaram Bapa Annakshetra for memorable events and celebrations. Experience the joy of festivities with our warm hospitality."
        />

        <meta
          property="og:title"
          content="Events in Shri Jalaram Bapa Annakshetra"
        />
        <meta
          property="og:description"
          content="Join us at Shri Jalaram Bapa Annakshetra for memorable events and celebrations. Experience the joy of festivities with our warm hospitality."
        />
        <meta
          name="keywords"
          content="best place to donate food, food donation, food charity, food drive, charities that donate food, charities for feeding the hungry ,charities feeding the poor, charities that give food to the poor, best charities for feeding the poor, feed the poor, giving food to the needy"
        />
        <link rel="canonical" href="https://jrbannakshetra.com/events-in-shri-jalaram-bapa-annakshetra" />
      </MetaTags>
      {/* ============== Event Section1 =========== */}
      <div className="eventSection1">
        <div className="eventBanner">
          <div className="eventBanner1">
            <Zoom>
              <h1>All religious festivals are celebrated at our place regularly.</h1>
            </Zoom>
            {/* <Fade left>
              <p>We work to provide global support and assistance of any kind where there is humanitarian like food donation , charity and welfare need.</p>
            </Fade> */}
          </div>
        </div>
      </div>

      {/* ========= Event Section2 ========== */}
      <div className="events-list mt-5">
        <div className="container">
          <div className="home-event_Header">
            <h4>
              Our on-going Events in <span>Shri Jalaram Bapa Annakshetra</span>
            </h4>
          </div>

          <div className="row">
            <div className="col-md-6 eve-loc">
              <div className="event-details">
                <div className="event-content">
                  <div className="events-head">
                    <h3>Food Distribution </h3>
                  </div>
                  <p>
                  jalaram bapa foundation help to provide emergency food parcels to people in crisis and support thousands of families in communities across the area.jalaram bapa foundation also topped up the donations during the collection with food resources from its own kitchens to ensure more food can get to where it is most needed.
                  </p>
                  <Link to="/food-donation-annakshetra">Donate now</Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 eve-loc">
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="MySwiper"
              >
                <SwiperSlide>
                  <div className="Event-img">
                    <img
                     src={Img5}
                     alt="food distribution"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Event-img">
                    <img
                      src="https://wishesandblessings.net/public/images/cause/food/4.jpg"
                      alt="food distribution"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Event-img">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNd2Y7FdIUyuoDVL8UhdYmWkP15Toda-PlAWRK_72R3qY89DFtBVP-CzkMDNdWNhI6KY4&usqp=CAU"
                      alt="food distribution"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 eve-loc order-md-1 order-2">
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="MySwiper"
              >
                <SwiperSlide>
                  <div className="Event-img">
                    <img
                     src={Img2}
                      alt="pitru paksha pooja"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Event-img">
                  <img
                     src={Img7}
                      alt="pitru paksha pooja"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Event-img">
                  <img
                     src={Img8}
                      alt="pitru paksha pooja"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-md-6 eve-loc order-md-2 order-1">
              <div className="event-details">
                <div className="event-content">
                  <div className="events-head">
                    <h3>Pritru Paksha Pooja</h3>
                  </div>
                  <p>
                  Shradh Puja or the Pitru Paksha is conducted to pay homage to the departed and beloved Ancestors. It is believed that the Shradh Pujas are performed with dedication and admiration to help the souls rest in peace making them bless their kin with assurance, peace, and thriving.
                  </p>
                    <Link to="/food-donation-annakshetra">Donate now</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 eve-loc">
              <div className="event-details">
                <div className="event-content">
                  <div className="events-head">
                    <h3>Tirth  Yatra Programs</h3>
                  </div>
                  <p>
                  This foundation organize pilgrimage in a proper manner in which the pilgrims are to be provided good quality food, Satsang programmes, medical facilities and complete information on the places they visit.
                  </p>
                  <Link to="/food-donation-annakshetra">Donate now</Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 eve-loc">
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="MySwiper"
              >
                <SwiperSlide>
                  <div className="Event-img">
                    <img
                      src={Img9}
                      alt="tirth yatra"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Event-img">
                  <img
                      src={Img10}
                      alt="tirth yatra"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Event-img">
                  <img
                      src={Img11}
                      alt="tirth yatra"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
    
        
          <div className="row">
            <div className="col-md-6 eve-loc order-md-1 order-2">
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="MySwiper"
              >
                <SwiperSlide>
                  <div className="Event-img">
                    <img
                     src={Img15}
                      alt="temple"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Event-img">
                  <img
                      src={Img14}
                      alt="temple"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Event-img">
                  <img
                      src={Img13}
                      alt="temple"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-md-6 eve-loc order-md-2 order-1">
              <div className="event-details">
                <div className="event-content">
                  <div className="events-head">
                    <h3>Temple Events</h3>
                  </div>
                  <p>
                  Religious, educational, and social activities are conducted at jalaram bapa foundation . All religious Festivals, according to the Panchang (Hindu lunar calendar), are celebrated at our place regularly. 
                  </p>
                  <Link to="/food-donation-annakshetra">Donate now</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 eve-loc">
              <div className="event-details">
                <div className="event-content">
                  <div className="events-head">
                    <h3>Normal Events</h3>
                  </div>
                  <p>
                  Our festival team brings strategy, creativity and depth of experience. All functions carried out in the temple are all meant to support and encourage the betterment and success of these key services.
                  </p>
                  <Link to="/food-donation-annakshetra">Donate now</Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 eve-loc">
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="MySwiper"
              >
                <SwiperSlide>
                  <div className="Event-img">
                    <img
                      src={Img18}
                      alt="normal events"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Event-img">
                  <img
                      src={Img16}
                      alt="normal events"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Event-img">
                  <img
                      src={Img17}
                      alt="normal evens"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 eve-loc order-md-1 order-2">
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="MySwiper"
              >
                <SwiperSlide>
                  <div className="Event-img">
                  <img
                      src={Img14}
                      alt="temple"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Event-img">
                  <img
                      src={Img15}
                      alt="temple"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Event-img">
                  <img
                      src={Img13}
                      alt="temple"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-md-6 eve-loc order-md-2 order-1">
              <div className="event-details">
                <div className="event-content">
                  <div className="events-head">
                    <h3>Festival Events</h3>
                  </div>
                  <p>
                   At our foundation we all take part with the teachers and devotees, manage and enjoy these celebrations.
                  </p>
                  <Link to="/food-donation-annakshetra">Donate now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <PopMail />
    </>
  );
};

export default Event;
