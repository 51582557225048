import React, { useState } from "react";
import "../Css/Home.css";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { MetaTags } from "react-meta-tags";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import Footer from "../Components/Footer";
import PopMail from "../Components/PopMail";
import { Link } from "react-router-dom";
import Founder from "../img/logo-white.png";
import festival from "../img/festival/festival1.jpg";
import prith from "../img/festival/pitra2.jpg";
import festival4 from "../img/festival/festival4.jpg";
import festival7 from "../img/festival/festival7.avif";
import Donate from "../img/festival/donate.jpg";
import Disasters from "../img/festival/dasaster1.jpeg";
import Tirth from "../img/festival/trith5.jpg";


const Home = () => {

  return (
    <>
      {/* ========= SEO ========== */}

      <MetaTags>
        <title>
        Shri Jalaram Bapa Foundation (NGO) 
        </title>
        <meta
          name="description"
          content="Our Foundation is a non-profit organization that collects food donations and distributes them to those in need."
        />
        
        <meta
          property="og:title"
          content="Shri Jalaram Bapa Foundation (NGO)"
        />
        <meta
          property="og:description"
          content="Our Foundation is a non-profit organization that collects food donations and distributes them to those in need."
        />
        <meta
          name="keywords"
          content="best place to donate food, food donation, food charity, food drive, charities that donate food, charities for feeding the hungry ,charities feeding the poor, charities that give food to the poor, best charities for feeding the poor, feed the poor, giving food to the needy"
        />
        <link rel="canonical" href="https://jrbannakshetra.com/" />
      </MetaTags>

      {/* ======== SEO end ======= */}
      {/* ============== Home Section1 =========== */}
      <div className="Section1">
        <div className="homeBanner">
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="MySwiper"
          >
            <SwiperSlide>
              <div className="swiperbanner1">
                <div className="homeAnimation1">
                  {/* <Zoom>
                    <h1>We create festivals that slap hard, taste great, look cool, and feel good.</h1>
                  </Zoom> */}
                  <Fade Left>
                    <p>
                    If you do a good job for others, you heal yourself at the same time, because a dose of joy is a spiritual cure. It transcends all barriers.
                    </p>{" "}
                  </Fade>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiperbanner2">
                <div className="homeAnimation1">
                  {/* <Zoom>
                    <h1>A Lifelong Example of Grace and Compassion</h1>
                  </Zoom> */}
                  <Fade Left>
                    <p>The great thing to remember is that though our feelings come and go God's love for us does not.</p>{" "}
                  </Fade>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiperbanner3">
                <div className="homeAnimation1">
                  {/* <Zoom>
                    <h1>Make Your Dream Smile A Reality</h1>
                  </Zoom> */}
                  <Fade Left>
                    <p>
                      We rely heavily on donation from  well
                      wishers.
                    </p>{" "}
                  </Fade>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      {/* =========== home Section2 ================ */}
      <div class="home-page-icon-boxes mt-5">
        <div class="container">
          <div class="row">
            <div className="col-md-4 mb-4">
              <div className="event-card">
                <div className="serviceCard">
                  {/* <div className="serviceImage">
                          <img src={ServiceImage} alt="" />
                        </div> */}
                  <div className="serviceTitle">
                    <h3>Tirth Yatra</h3>
                  </div>
                  <div className="serviceText">
                    <p>
                      We conduct pilgrimage in an organized manner in which the
                      pilgrims are provided with good quality food, Satsang
                      programmes and medical facilities.
                    </p>
                  </div>
                  <div className="service-btn">
                    <a href="/food-donation">
                      {" "}
                      <Link to="/food-donation">Know More</Link>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="event-card">
                <div className="serviceCard">
                  {/* <div className="serviceImage">
                          <img src={ServiceImage} alt="" />
                        </div> */}
                  <div className="serviceTitle">
                    <h3>Rural &amp; Urban Area</h3>
                  </div>
                  <div className="serviceText">
                    <p>
                      Rural and Urban areas are interdependent, and this holds
                      importance to their respective development in social and
                      economic spheres.
                    </p>
                  </div>
                  <div className="service-btn">
                    <a href="/food-donation">
                      {" "}
                      <Link to="/food-donation">Know More</Link>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="event-card">
                <div className="serviceCard">
                  {/* <div className="serviceImage">
                          <img src={ServiceImage} alt="" />
                        </div> */}
                  <div className="serviceTitle">
                    <h3>Natural Disasters</h3>
                  </div>
                  <div className="serviceText">
                    <p>
                      When Disasters Occur We Work To Address the Urgent Needs
                      of Those Who Are Most Vulnerable.
                    </p>
                  </div>
                  <div className="service-btn">
                    <a href="/food-donation">
                      {" "}
                      <Link to="/food-donation">Know More</Link>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="col-12 col-md-6 col-lg-4 mt-4 mt-lg-0">
              <div class="icon-box ">
                <figure class="d-flex justify-content-center">
                  <img src="images/hands-gray.png" alt="" />
                  <img src="images/hands-white.png" alt="" />
                </figure>
                <header class="entry-header">
                  <h3 class="entry-title">Tirth Yatra</h3>
                </header>
                <div class="entry-content mb-4">
                  <p>
                   
We conduct pilgrimage in an organized manner in which the pilgrims are provided good quality food, Satsang programmes, medical facilities.
                  </p>
                </div>
                <div className="service-btn">
                  <a href="/Service">
                    {" "}
                    <Link to="/Service">Know More</Link>
                  </a>
                </div>
              </div>
            </div> */}
            {/* <div class="col-12 col-md-6 col-lg-4 mt-4 mt-lg-0">
              <div class="icon-box">
                <figure class="d-flex justify-content-center">
                  <img src="images/donation-gray.png" alt="" />
                  <img src="images/donation-white.png" alt="" />
                </figure>
                <header class="entry-header">
                  <h3 class="entry-title">Rural &amp; Urban Area</h3>
                </header>
                <div class="entry-content mb-4">
                  <p>
                   
Rural and urban areas are interdependent, and this holds importance to their respective development in social and economic spheres.
                  </p>
                </div>
                <div className="service-btn">
                  <a href="/Service">
                    {" "}
                    <Link to="/Service">Know More</Link>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mt-4 mt-lg-0">
              <div class="icon-box">
                <figure class="d-flex justify-content-center">
                  <img src="images/charity-gray.png" alt="" />
                  <img src="images/charity-white.png" alt="" />
                </figure>
                <header class="entry-header">
                  <h3 class="entry-title">Natural Disasters</h3>
                </header>
                <div class="entry-content mb-4">
                  <p>
                    When Disasters Occur We Work To Address the Urgent Needs of Those Who Are Most Vulnerable.
                  </p>
                </div>
                <div className="service-btn">
                  <a href="/Service">
                    {" "}
                    <Link to="/Service">Know More</Link>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* ============== Home Section3 =========== */}
      <div class="home-page-welcome mt-5">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6 ">
              <div class="welcome-content">
                <div className="home-about_Header">
                  <h4>
                     <span>Shri Jalaram Bapa Annakshetra</span>
                  </h4>
                </div>
                <div class="entry-content mt-5">
                  <p>
                    The Jalaram Bapa trust was started with the main objective
                    to eradicate poverty by uplifting the needy poor rural
                    population of India by extending education, providing health
                    facilities, elimination of social discrimination and
                    eradicating the culture of disparity in socialization. We
                    are working for providing food to the needy people.We helps
                    to provide global support and assistance to areas where
                     humanitarian like food donation , charity and
                    welfare need.
                  </p>
                </div>
                <div className="about-btn">
                  <a href="/food-donation-annakshetra">
                    <Link to="/food-donation-annakshetra">Donate Now </Link>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div className="founder-img">
                <img src={Founder} alt="shri-raghubar-dutt-tiwari" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ============== Home Section4 =========== */}
      <div class="home-page-events">
        <div class="container">
          <div className="home-event_Header">
            <h4>
              Our on-going Events in <span>Shri Jalaram Bapa Annakshetra</span>
            </h4>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="upcoming-events">
              <div class="event-wrap d-flex flex-wrap justify-content-between">
                  <figure class="m-0">
                    <img src={Donate} alt="food-donation" />
                  </figure>
                  <div class="event-content-wrap">
                    <header class="entry-header d-flex flex-wrap align-items-center">
                      <h3 class="entry-title w-100 m-0">
                        <a href="#">Food Donation Event</a>
                      </h3>
                      {/* <div class="posted-date">
                        <a href="#">Aug 25, 2018 </a>
                      </div>
                      <div class="cats-links">
                        <a href="#">Ball Room New York</a>
                      </div> */}
                    </header>
                    <div class="entry-content">
                      <p class="m-0">
                        Jalaram Bapa foundation helps to provide emergency food
                        parcels to people in crisis and support thousands of
                        families in communities across the area.
                        {/* jalaram bapa foundation also topped up the donations during the collection with food resources from its own kitchens to ensure more food can get to where it is most needed. */}
                      </p>
                    </div>
                    <div className="event-btn">
                      <a href="/event-shri-jalaram-bapa-foundation">
                        <Link to="/event-shri-jalaram-bapa-foundation">Read More</Link>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="event-wrap d-flex flex-wrap justify-content-between">
                  <figure class="m-0">
                    <img src={prith} alt="pitru paksha" />
                  </figure>
                  <div class="event-content-wrap">
                    <header class="entry-header d-flex flex-wrap align-items-center">
                      <h3 class="entry-title w-100 m-0">
                        <a href="#">Pitru Paksha Event</a>
                      </h3>
                      {/* <div class="posted-date">
                        <a href="#">Aug 25, 2018 </a>
                      </div>
                      <div class="cats-links">
                        <a href="#">Ball Room New York</a>
                      </div> */}
                    </header>
                    <div class="entry-content">
                      <p class="m-0">
                        Shradh Puja or the Pitru Paksha is conducted to pay
                        homage to the departed and beloved Ancestors.
                        {/* It is believed that the Shradh Pujas are performed with dedication and admiration to help the souls rest in peace making them bless their kin with assurance, peace, and thriving. */}
                      </p>
                    </div>
                    <div className="event-btn">
                      <a href="/event-shri-jalaram-bapa-foundation">
                        <Link to="/event-shri-jalaram-bapa-foundation">Read More</Link>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="event-wrap d-flex flex-wrap justify-content-between">
                  <figure class="m-0">
                    <img src={Tirth} alt="tirth yatra" />
                  </figure>
                  <div class="event-content-wrap">
                    <header class="entry-header d-flex flex-wrap align-items-center">
                      <h3 class="entry-title w-100 m-0">
                        <a href="#">Tirth Yatra Event</a>
                      </h3>
                      {/* <div class="posted-date">
                        <a href="#">Aug 25, 2018 </a>
                      </div>
                      <div class="cats-links">
                        <a href="#">Ball Room New York</a>
                      </div> */}
                    </header>
                    <div class="entry-content">
                      <p class="m-0">
                        We organize pilgrimage in a proper manner in which the
                        pilgrims are to be provided good quality food, Satsang
                        programmes, medical facilities and complete information
                        on the places they visit.
                      </p>
                    </div>
                    <div className="event-btn">
                      <a href="/event-shri-jalaram-bapa-foundation">
                        <Link to="/event-shri-jalaram-bapa-foundation">Read More</Link>
                      </a>
                    </div>
                  </div>
                </div>
             
              
            
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="upcoming-events">
              <div class="event-wrap d-flex flex-wrap justify-content-between">
                  <figure class="m-0">
                    <img src={festival7} alt="festival events" />
                  </figure>
                  <div class="event-content-wrap">
                    <header class="entry-header d-flex flex-wrap align-items-center">
                      <h3 class="entry-title w-100 m-0">
                        <a href="#">Festival Events</a>
                      </h3>
                      {/* <div class="posted-date">
                        <a href="#">Aug 25, 2018 </a>
                      </div>
                      <div class="cats-links">
                        <a href="#">India </a>
                      </div> */}
                    </header>
                    <div class="entry-content">
                      <p class="m-0">
                        At our foundation we all take part with the teachers and
                        devotees, manage and enjoy these celebrations.
                      </p>
                    </div>
                    <div className="event-btn">
                      <a href="/event-shri-jalaram-bapa-foundation">
                        <Link to="/event-shri-jalaram-bapa-foundation">Read More</Link>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="event-wrap d-flex flex-wrap justify-content-between">
                  <figure class="m-0">
                    <img src={festival} alt="normal event" />
                  </figure>
                  <div class="event-content-wrap">
                    <header class="entry-header d-flex flex-wrap align-items-center">
                      <h3 class="entry-title w-100 m-0">
                        <a href="#">Normal Event</a>
                      </h3>
                      {/* <div class="posted-date">
                        <a href="#">Aug 25, 2018 </a>
                      </div>
                      <div class="cats-links">
                        <a href="#">Ball Room New York</a>
                      </div> */}
                    </header>
                    <div class="entry-content">
                      <p class="m-0">
                        {/* Our festival team brings strategy, creativity and depth of experience. */}
                        All functions carried out in the temple are all meant to
                        support and encourage the betterment and success of
                        these key services.
                      </p>
                    </div>
                    <div className="event-btn">
                      <a href="/event-shri-jalaram-bapa-foundation">
                        <Link to="/event-shri-jalaram-bapa-foundation">Read More</Link>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="event-wrap d-flex flex-wrap justify-content-between">
                  <figure class="m-0">
                    <img src={festival4} alt="temple events" />
                  </figure>
                  <div class="event-content-wrap">
                    <header class="entry-header d-flex flex-wrap align-items-center">
                      <h3 class="entry-title w-100 m-0">
                        <a href="#">Temple Event</a>
                      </h3>
                      {/* <div class="posted-date">
                        <a href="#">Aug 25, 2018 </a>
                      </div>
                      <div class="cats-links">
                        <a href="#">Ball Room New York</a>
                      </div> */}
                    </header>
                    <div class="entry-content">
                      <p class="m-0">
                        Religious, educational, and social activities are
                        conducted at the Jalaram Bapa Foundation .
                        {/* All religious Festivals, according to the Panchang (Hindu lunar calendar), are celebrated at our place regularly.  */}
                      </p>
                    </div>
                    <div className="event-btn">
                      <a href="/event-shri-jalaram-bapa-foundation">
                        <Link to="/event-shri-jalaram-bapa-foundation">Read More</Link>
                      </a>
                    </div>
                  </div>
                </div>
          
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ========== Home Section5 ============= */}
      <div className="our-causes">
        <div className="container">
          <div className="home-services_Header">
            <h4>
              Our Services In <span>Shri Jalaram Bapa Foundation</span>
            </h4>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="Swiper"
          >
            <SwiperSlide>
              <div class="home-service">
                <figure class="m-0">
                  <img src={Donate} alt="rural urban area" />
                </figure>
                <div class="home-service-detail">
                  <h3>
                    <Zoom>Rural & Urban Area </Zoom>
                  </h3>
                  <p>
                    Rural development is the cornerstone of sustainable
                    development, and we recognize its importance. The truth,
                    however, is different in that rural issues tend to take a
                    backseat compared to urban problems.
                  </p>
                  {/* <div className="event-btn">
                      <a href="/Event">
                        <Link to="/Event">Read More</Link>
                      </a>
                    </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="home-service">
                <figure class="m-0">
                  <img src={Disasters} alt="natural disaster" />
                </figure>
                <div class="home-service-detail">
                  <h3>
                    <Zoom>Natural Disasters</Zoom>
                  </h3>
                  <p>
                  In the face of a crisis often the difference between life
                    and death is immediate access to relief items. We are a
                    pioneer in delivering life-saving relief supplies on time at
                    scale. Help us to do more.
                  </p>
                 
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="home-service">
                <figure class="m-0">
                  <img src={Tirth} alt="pilgrims" />
                </figure>
                <div class="home-service-detail">
                  <h3>
                    <Zoom>Tirth Yatra </Zoom>
                  </h3>
                  <p>
                    India has been considered as a land of diverse cultures and
                    religions. People of different faiths undertake Tirthyatra
                    to numerous destinations to pay homage to their sacred
                    deities. Help us to conduct Tirthyatra for needy people.
                  </p>
                 
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="home-service">
                <figure class="m-0">
                  <img src={prith} alt="shradh paksha" />
                </figure>
                <div class="home-service-detail">
                  <h3>
                    <Zoom>Pitru Paksha </Zoom>
                  </h3>
                  <p>
                    Donate during Pitru paksha, Ancestors are eagerly waiting
                    for blessings.
                  </p>
                  
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="home-service">
                <figure class="m-0">
                  <img src={festival7} alt="tyohar" />
                </figure>
                <div class="home-service-detail">
                  <h3>
                    <Zoom>Festival Events</Zoom>
                  </h3>
                  <p>
                  At our foundation, we all take part with the teachers and
                    devotees, manage and enjoy these celebrations. Religious,
                    educational, and social activities are conducted at the Jalaram
                    Bapa Foundation.
                  </p>
                 
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      {/* ========= Home Section6 ============== */}
      <div class="home-page-limestone mt-5">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-lg-6">
              <div class="section-heading">
                <div className="home-event_Header">
                  <h4>
                    {" "}
                    Our Contribution{" "}
                    <span> for health,food and spirituality</span>
                  </h4>
                </div>
                <p class="mt-5">
                Quality of life comes with proper health and properly educated mind and soul. We are committed to providing our children with the best quality of life.  We work with communities, mainly in rural areas, to help them prepare for and respond to natural disasters and the effects of climate change.
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="row">
                <div class="col-12 col-sm-4 mt-5 mt-lg-0">
                  <div class="counter-box">
                    <div class="teamwork">
                      <img
                        data-cfsrc="images/teamwork.png"
                        alt=""
                        src="https://preview.colorlib.com/theme/thecharity/images/teamwork.png"
                      />
                    </div>
                    <div class="d-flex justify-content-center align-items-baseline  mt-5">
                      <div
                        class="start-counter "
                        data-to="120"
                        data-speed="2000"
                      >
                        <h2> 120</h2>
                      </div>
                      <div class="counter-k">K</div>
                    </div>
                    <h3 class="entry-title">Tirth Yatra Help</h3>
                  </div>
                </div>
                <div class="col-12 col-sm-4 mt-5 mt-lg-0">
                  <div class="counter-box">
                    <div class="d-flex justify-content-center align-items-center">
                      <img
                        data-cfsrc="images/donation.png"
                        alt="pilgrimage help"
                        src="https://preview.colorlib.com/theme/thecharity/images/donation.png"
                      />
                    </div>
                    <div class="d-flex justify-content-center align-items-baseline mt-5">
                      <div class="start-counter" data-to="79" data-speed="2000">
                        <h2>79</h2>
                      </div>
                    </div>
                    <h3 class="entry-title">Rural & Urban Help</h3>
                  </div>
                </div>
                <div class="col-12 col-sm-4 mt-5 mt-lg-0">
                  <div class="counter-box">
                    <div class="d-flex justify-content-center align-items-center">
                      <img
                        data-cfsrc="images/dove.png"
                        alt="pandemic"
                        src="https://preview.colorlib.com/theme/thecharity/images/dove.png"
                      />
                    </div>
                    <div class="d-flex justify-content-center align-items-baseline mt-5">
                      <div
                        class="start-counter"
                        data-to="253"
                        data-speed="2000"
                      >
                        <h2>253</h2>
                      </div>
                    </div>
                    <h3 class="entry-title">Natural Disasters Help</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <PopMail />
    </>
  );
};

export default Home;
