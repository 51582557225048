
import Man from '../img/demoperson.png';
// import Suman from '../img/volunteer/suman.jpg';
import Ankur from '../img/volunteer/ankur.jpg';
import Bhatt from '../img/volunteer/bhatt.jpg';
import Gopal from '../img/volunteer/gopal.jpg';
import Joshi from '../img/volunteer/joshi.jpg';
import Prakash from '../img/volunteer/prakash.jpg';
import Puran from '../img/volunteer/puran.jpg';
import Siddhi from '../img/volunteer/siddhi.jpg';
import Surekha from '../img/volunteer/surekha.jpg';
import Umesh from '../img/volunteer/umesh.jpg';
import Harish  from '../img/volunteer/harish.jpg';
import Ambrish from '../img/volunteer/ambrish.jpg';
import Mahesh  from '../img/volunteer/mahesh.jpg';
import Archana from '../img/volunteer/Dr. archana bhaguna.jpg'


const VolinterData = [
    {
        
            id:1,
            Category:"Uttarakhand",
            VDist:"Secretary, JRB Foundation",
            Vhead:"Dehradun, Uttarakhand",
            Vdesc:"Puran Chand Gaur",
            VImage:Puran,
            VName:"9412057874",
        },
        {
            id:1,
            Category:"Mumbai",
            VDist:"Treasurer, JRB Foundation",
            Vhead:"Mumbai",
            Vdesc:"Dr. Archana Bahuguna",
            VImage:Archana,
            VName:"7021940508",
        },
        {
        id:1,
        Category:"Uttarakhand",
        VDist:"",
        Vdesc:"Shri Gopal Singh Bisht",
        Vhead:" Zonal Office - Tani, Naubara, Uttarakhand",
        VImage:Gopal,
        VName:"9988193699",
    },
    {
        id:1,
        Category:"Uttarakhand",
        VDist:"",
        Vhead:"Dehradun ,Uttarakhand",
        Vdesc:"Ankur Gaur",
        VImage:Ankur,
        VName:"7300899994",
    },
    
    {
        id:1,
        Category:"Uttarakhand",
        VDist:"",
        Vhead:"Haldwani , Uttarakhand",
        Vdesc:"Siddhi Tandon",
        VImage:Siddhi,
        VName:"8958864390",
    },
    {
        id:1,
        Category:"Uttarakhand",
        VDist:"",
        Vhead:"Haridwar, Uttarakhand",
        Vdesc:"Umesh Tripathi",
        VImage:Umesh,
        VName:"9927100301",
    },
    {
        id:1,
        Category:"Uttarakhand",
        VDist:"",
        Vhead:"Ram Nagar, Uttarakhand",
        Vdesc:"Harish Chandra Sati",
        VImage:Harish,
        VName:"8057524700",
    },
    
    {
        id:2,
        Category:"Madhya Pradesh",
        VDist:"",
        Vhead:"Gwalior, Madhya Pradesh ",
        Vdesc:"Deval Joshi",
        VImage:Joshi,
        VName:"9827557133",
    },
   
    {
        id:3,
        Category:"Mumbai",
        VDist:"",
        Vhead:"Andheri, Mumbai",
        Vdesc:"Ambrish Pathak",
        VImage:Ambrish,
        VName:"7039212103",
    },
    {
        id:3,
        Category:"Mumbai",
        VDist:"",
        Vhead:"Vasai ,Thane Mumbai",
        Vdesc:"Prakash Tiwari",
        VImage:Prakash,
        VName:"7387938678",
    },
    {
        id:3,
        Category:"",
        VDist:"",
        Vhead:" Thane ,Mumbai",
        Vdesc:"Surekha Chaudhary",
        VImage:Surekha,
        VName:"9821064130",
    },
    {
        id:3,
        Category:"Mumbai",
        VDist:"",
        Vhead:"Nerul, Navi-Mumbai",
        Vdesc:"K.N Bhatt",
        VImage:Bhatt,
        VName:"8082567846",
    },
    {
        id:3,
        Category:"Mumbai",
        VDist:"",
        Vhead:"Nerul, Navi-Mumbai",
        Vdesc:"Mahesh Chandra Joshi",
        VImage:Mahesh,
        VName:"9930055430",
    },
   
    // {
    //     id:4,
    //     Category:"New Delhi",
    //     VDist:"",
    //     Vhead:"New Delhi",
    //     Vdesc:"Smt. Suman Kandpal",
    //     VImage:Suman,
    //     VName:"9599221472",
    // },
    
]

export default VolinterData;