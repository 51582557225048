import g1 from'../img/logo.png';
import Img1 from '../img/banner/img1.jpeg';
import Img2 from '../img/banner/img7.jpeg';
import Img3 from '../img/banner/img3.jpeg';
import Img4 from '../img/banner/img4.jpeg';
import Img5 from '../img/banner/img5.jpeg';
import Img6 from '../img/banner/img6.jpeg';
import Img7 from '../img/banner/img25.jpeg';
import Img8 from '../img/banner/img24.jpeg';
import Img9 from '../img/banner/img23.jpeg';
const GalleryData = [
    {
        id:1,
        Category:"Normal Donation",
        EImage:Img1,
        EventName:"Normal Donation",
    },
    {
        id:2,
        Category:"Festival Donation",
        EImage:Img2,
        EventName:"Festival Donation",
    },
    {
        id:3,
        Category:"Events",
        EImage:Img3,
        EventName:"Festival Event",
    },
    {
        id:4,
        Category:"Tirth Yatra",
        EImage:Img4,
        EventName:"Tirth Yatra Donation",
    },
    {
        id:5,
        Category:"Pitru Paksha",
        EImage:Img5,
        EventName:"Pitru Paksha Event",
    },
    {
        id:6,
        Category:"Rama Navami",
        EImage:Img6,
        EventName:"Rama Navami Event",
    },
    {
        id:7,
        Category:"Upcoming Event",
        EImage:Img7,
        EventName:"Hanuman Jayanti",
    },
    {
        id:8,
        Category:"Urban Area",
        EImage:Img8,
        EventName:"Urban Area Donation",
    },
    {
        id:9,
        Category:"Rural Area",
        EImage:Img9,
        EventName:"Rural Area Donation",
    },
]

export default GalleryData;