import './App.css';
import NavBar from './Components/NavBar';
import { BrowserRouter,HashRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Service from './Pages/Service';
import Event from './Pages/Event';
import Gallery from './Pages/Gallery';
import Contact from './Pages/Contact';
import Donation from './Pages/Donation';
import Call from './Components/CallBtn';
import WhatsApp from './Components/WhatsappBtn';

function App() {
 
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Call/>
        {/* <WhatsApp/> */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about-shri-jalaram-bapa-foundation' element={<About />} />
          <Route path='/food-donation' element={<Service/>} />
          <Route path='/event-shri-jalaram-bapa-foundation' element={<Event/>} />
          <Route path='/gallery-shri-jalaram-bapa-foundation' element={<Gallery/>} />
          <Route path='/contact-us' element={<Contact/>} />
          <Route path='/food-donation-annakshetra' element={<Donation/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
