import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "../Css/ServicesModal.css";



function MoDal(props) {
    const { id, modalheader, PopupHeader, PopupImage, PointOne, PointTwo, PointThree, PointFour, PointFive, PointSix, PointSeven, PointEight, PointNine, ParaOne, ParaTwo } = props.viewData;
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                     <h2>{modalheader}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className="col-md-5">
                        <div className="serviceImgView">
                            <img src={PopupImage} alt='' />
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="serviceDetail">
                            <div className="serviceViewTitle">
                                <h2>{PopupHeader}</h2>
                            </div>
                            <div className="serviceViewpoint">
                                <p>{PointOne}</p>
                                <p>{PointTwo}</p>
                                <p>{PointThree}</p>
                                <p>{PointFour}</p>
                                <p>{PointFive}</p>
                                <p>{PointSix}</p>
                                <p>{PointSeven}</p>
                                <p>{PointEight}</p>
                                <p>{PointNine}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="serviceViewText">
                            <p>{ParaOne}</p>
                            <p>{ParaTwo}</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MoDal;
