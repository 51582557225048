import React, { useState, useLayoutEffect } from "react";
import "../Css/Service.css";
import Footer from "../Components/Footer.jsx";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Scan from "../img/banner/img2.jpg";
import ServiceData from "../Components/ServiceData";
import MoDal from "../Components/MoDal";
import Button from "react-bootstrap/Button";
import PopMail from "../Components/PopMail";
import { MetaTags } from "react-meta-tags";
const Service = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  // =========================== Service View ====================
  const [modalShow, setModalShow] = useState(false);
  const [serviceId, setServiceId] = useState();

  const [finalData, setFinalData] = useState([]);

  const serviceView = (id, condition) => {
    setModalShow(condition);
    setServiceId(id);

    if (id !== "") {
      console.log(id);
      setFinalData(ServiceData.find((data) => data.id === id));
    }
  };

  return (
    <>
      {/* ========= SEO ========== */}

      <MetaTags>
        <title>
        Daan Yagna for Feeding Poor People | JRB Foundation
        </title>
        <meta
          name="description"
          content="The work of a food donation foundation is crucial in ensuring that everyone has access to nutritious food, regardless of their socioeconomic status."
        />

        <meta
          property="og:title"
          content="Daan Yagna for Feeding Poor People | JRB Foundation"
        />
        <meta
          property="og:description"
          content="The work of a food donation foundation is crucial in ensuring that everyone has access to nutritious food, regardless of their socioeconomic status."
        />
        <meta
          name="keywords"
          content="best place to donate food, food donation, food charity, food drive, charities that donate food, charities for feeding the hungry ,charities feeding the poor, charities that give food to the poor, best charities for feeding the poor, feed the poor, giving food to the needy"
        />
        <link rel="canonical" href="https://jrbannakshetra.com/food-donation" />
      </MetaTags>
      {/* ============== Service Section1 =========== */}
      <div className="serviceSection1">
        <div className="serviceBanner">
          <div className="serviceBanner1">
            <Zoom>
              <h1>Daan Yagna for Feeding Poor People</h1>
            </Zoom>
            <Fade left>
              <p>Our  foundation is a non-profit organization</p>
            </Fade>
          </div>


          
        </div>
      </div>

      {/* ============ Services Section2===== */}
      <div className="Section4 mt-5">
        <div className="container">
          <div className="home-event_Header">
            <h4>
              What We Do In <span>Shri Jalaram Bapa Foundation</span>
            </h4>
          </div>
          <div className="row mt-5">
            {ServiceData.map((item, index) => {
              const { id, ServiceImage, Header, ShortDesc } = item;
              return (
                <>
                  <div className="col-md-4 mb-4">
                    <div className="event-card">
                      <div className="serviceCard">
                        <div className="serviceImage">
                          <img src={ServiceImage} alt="donation" />
                        </div>
                        <div className="serviceTitle">
                          <h3>{Header}</h3>
                        </div>
                        <div className="serviceText">
                          <p>{ShortDesc}</p>
                        </div>
                        <div className="aboutButton">
                          <Button
                            className=" viewServiceButton"
                            onClick={() => serviceView(id, true)}
                          >
                            Know More
                          </Button>

                          <MoDal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            id={serviceId}
                            viewData={finalData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      {/* ========= Contact Section4 ======== */}
      <div className="contactSection2 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <form action="">
                <div class="bank-detail">
                  <h3>Bank Details</h3>

                  <div className="row">
                    <div className="col-12">
                      <div className="acc-head">
                        <h6>Bank Name</h6>
                      </div>
                      <div className="acc-txt">
                        <h6>HDFC Bank</h6>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="acc-head">
                        <h6>Account Details</h6>
                      </div>
                      <div className="acc-txt">
                        <h6>50200076772438</h6>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="acc-head">
                        <h6>IFSC Code</h6>
                      </div>
                      <div className="acc-txt">
                        <h6>HDFC0000666</h6>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="acc-head">
                        <h6>Account Holder </h6>
                      </div>
                      <div className="acc-txt">
                        <h6>SRI JALARAM BAPA FOUNDATION</h6>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="acc-head">
                        <h6>Branch Name</h6>
                      </div>
                      <div className="acc-txt">
                        <h6>SEVEN BANGLOWS ANDHERI(W)</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-6 col-12">
              <div class="Scan-side">
                <h3>Donate through QR code</h3>
                <div className="pay-img">
                  <div className="scanner-img">
                    <img src={Scan} alt="qr code" />
                  </div>
                  <Fade left>
                    <h6>
                      Scan With GooglePay , PhonePay OR Any UPI BarCode Scanner
                      For Payments
                    </h6>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <PopMail />
    </>
  );
};

export default Service;
