import ser2 from "../img/logo.png";
import festival from "../img/festival/festival1.jpg";
import prith from "../img/festival/pitra2.jpg";
import festival4 from "../img/festival/festival4.jpg";
import festival7 from "../img/festival/festival7.avif";
import Donate from "../img/festival/donate.jpg";
import Disasters from "../img/festival/dasaster1.jpeg";
import Trith from "../img/festival/trith5.jpg";

const ServiceData = [
  // {
   
  //     id: 1,
  //     ServiceImage: ser2,
  //     Header: "Food Distribution",
  //     FooterHead: "Food Distribution",
  //     ShortDesc:
  //       "Giving food to the hungry is a great donation and donation of the knowledge is considered important among all other donations. By food, one's hunger is fulfilled and one can survive then whereas the knowledge attained helps one to survive whole life.",
  //     modalheader: "Food Distribution",
  //     PopupHeader: "Food Distribution",
  //     PopupImage: Donate,
  //     PointOne: "1. Food Distribution In Rural Area & Urban Area",
  //     PointTwo: "2. Food Distribution In Festival Days",
  //     PointThree: "3. Food Distribution In Pitra Paksha ",
  //     PointFour: "4. Food Distribution in Tirth  Yatra People",
  //     PointFive: "5. Food Distribution in  Natural Disaster",
  //     ParaOne:
  //       "Quality of life comes with proper health and properly educating mind and soul. We are committed to providing our children with the best quality of life.",
  //       ParaTwo:
  //       "Giving food to the hungry is a great donation and donation of the knowledge is considered important among all other donations. By food, one's hunger is fulfilled and one can survive then whereas the knowledge attained helps one to survive whole life.",
  //   },
    {
      id: 1,
      ServiceImage: ser2,
      Header: "Pitru Paksha Pooja",
      FooterHead: "Pitru Paksha Pooja",
      ShortDesc:
        "Shradh Puja or the Pitru Paksha is conducted to pay homage to the departed and beloved Ancestors. It is believed that the Shradh Pujas are performed with dedication and admiration to help the souls rest in peace making them bless their kin with assurance, peace, and thriving",
      modalheader: "Pitru Paksha Pooja",
      PopupHeader: "Pitru Paksha Pooa",
      PopupImage: prith,
      PointOne: "1. You can donate food items during Pitra Paksha",
      PointTwo: "2. You can organize an Annadanam, a community meal, during Pitra Paksha",
      PointThree: "3. Few ideas for food donation during Pitra Paksha",
  
      ParaOne:
      "Quality of life comes with proper health and properly educating mind and soul. We are committed to providing our children with the best quality of life.",
      ParaTwo:
        "Shradh Puja or the Pitru Paksha is conducted to pay homage to the departed and beloved Ancestors. It is believed that the Shradh Pujas are performed with dedication and admiration to help the souls rest in peace making them bless their kin with assurance, peace, and thriving",
    },
    {
      id: 2,
      ServiceImage: ser2,
      Header: "Tirth  Yatra Programs",
      FooterHead: "Tirth  Yatra programs ",
      ShortDesc:
        "India has been considered as a land of diverse cultures and religions. People of different faiths undertake tirth yatra to numerous destinations to pay homage to their sacred deities. Help us to conduct a tirth yatra for needy people.",
      modalheader: "Tirth  Yatra programs ",
      PopupHeader: "Tirth  Yatra programs ",
      PopupImage: Trith,
      PointOne: "1. We provide a Food for Pilgrims",
      PointTwo: "2. We Provide a Shelter To Tirth Yatri",
     
      ParaOne:
      "Quality of life comes with proper health and properly educating mind and soul. We are committed to providing our children with the best quality of life.",
      ParaTwo:
        "India has been considered as a land of diverse cultures and religions. People of different faiths undertake tirth yatra to numerous destinations to pay homage to their sacred deities. Help us to conduct a tirth yatra for needy people.",
    },
    {
    id: 3,
    ServiceImage: ser2,
    Header: "Festival Events",
    FooterHead: "Festival Events",
    ShortDesc:
      "At our foundation, we all take part with the teachers and devotees, manage and enjoy these celebrations.",
    modalheader: "Festival Events",
    PopupHeader: "Festival Events",
    PopupImage: festival,
    PointOne: "1.Donate for Ramanavmi",
    PointTwo: "2. Donate for Narsimha Chaturdashi",
    PointThree: "3. Donate for Janmasthmi,Shivratrti,Navratri",
    PointFour: "4. Donate for Diwali ",
    PointFive: "5. Donate for Gaur Purnima (Holi)",
    ParaOne:
      "Quality of life comes with proper health and properly educating mind and soul. We are committed to providing our children with the best quality of life.",
    ParaTwo:
      "At our foundation, we all take part with the teachers and devotees, manage and enjoy these celebrations.",
  },
  
  {
    id: 4,
    ServiceImage: ser2,
    Header: "Donation on Special Occassion",
    FooterHead: "Special Donation",
    ShortDesc:
      "You can donate for special days like Birthday , Anniversary , Punya  tithi etc.",
    modalheader: "Donation on Special Occassion",
    PopupHeader: "Donation on Special Occassion",
    PopupImage: Donate,
    PointOne: "1.Donate on Birthday ",
    PointTwo: "2. Donate for Marriage Ceremony ",
    PointThree: "3. Donate for Punya tithi",
    PointFour: "4. Other Occassion",
    ParaOne:
      "Quality of life comes with proper health and properly educating mind and soul. We are committed to providing our children with the best quality of life.",
    ParaTwo:
      "Your donation to the Jalaram Bapa Foundation will help ensure the future of our festival event programs across the country.",
  },
  {
    id: 5,
    ServiceImage: ser2,
    Header: "Temple Events",
    FooterHead: "Temple Donation",
    ShortDesc:
      "Religious, educational, and social activities are conducted at the Jalaram Bapa Foundation . All religious Festivals, according to the Panchang (Hindu lunar calendar), are celebrated at our place regularly. ",
    modalheader: "Temple Events",
    PopupHeader: "Temple Events",
    PopupImage: festival4,
    PointOne: "1. Jaganna Rath Yatra",
    PointTwo: "2. Shrimad Bhagwat Katha",
    PointThree: "3.Shivpuran  Katha",
    PointFour: "4. Shri Ram Katha ",
    PointFive: "5. Other Spiritual Discourse",
   
    ParaOne:
      "Quality of life comes with proper health and properly educating mind and soul. We are committed to providing our children with the best quality of life.",
    ParaTwo:
      "Religious, educational, and social activities are conducted at the Jalaram Bapa Foundation . All religious Festivals, according to the Panchang (Hindu lunar calendar), are celebrated at our place regularly. ",
  },
 
 
  {
    id: 6,
    ServiceImage: ser2,
    Header: "Rural Area Food Distribution",
    FooterHead: "Rural Area Distribution",
    ShortDesc:
      "Rural Areas are interdependent, and this holds importance to their respective development in social and economic spheres. Urban-rural linkages are important tools in the long-term vision of equitable and holistic development.",
    modalheader: "Rural/Remote Area Food Distribution",
    PopupHeader: "Rural Area Food Distribution",
    PopupImage: Disasters,
    PointOne: "1. We distribute  food in Rural Area",
    PointTwo: "2. We distribute Food for Require People",
    PointThree: "3. We distribute food for Festival ",
    PointFour: "4. we distribute Food at Normal Days",
    ParaOne:
    "Quality of life comes with proper health and properly educating mind and soul. We are committed to providing our children with the best quality of life.",
    ParaTwo:
      "Rural Areas are interdependent, and this holds importance to their respective development in social and economic spheres. Urban-rural linkages are important tools in the long-term vision of equitable and holistic development.",
  },
  {
    id: 7,
    ServiceImage: ser2,
    Header: "Urban Area Food distribution",
    FooterHead: "Urban Area distribution",
    ShortDesc:
      "Urban areas are interdependent, and this holds importance to their respective development in social and economic spheres. Urban-rural linkages are important tools in the long-term vision of equitable and holistic development.",
    modalheader: "Urban Area Food distribution",
    PopupHeader: "Urban Area Food distribution",
    PopupImage: Disasters,
    PointOne: "1. We distribute food in Urban Area",
    PointTwo: "2. We distribute Food for Require People",
    PointThree: "3. We distribute food for Festivals ",
    PointFour: "4. we distribute Food at Normal Days",
    ParaOne:
    "Quality of life comes with proper health and properly educating mind and soul. We are committed to providing our children with the best quality of life.",
    ParaTwo:
      "Urban areas are interdependent, and this holds importance to their respective development in social and economic spheres. Urban-rural linkages are important tools in the long-term vision of equitable and holistic development.",
  },
  // {
  //   id: 9,
  //   ServiceImage: ser2,
  //   Header: "Normal Donation",
  //   FooterHead: "Normal Donation",
  //   ShortDesc:
  //     "All functions carried out in the temple are meant to support and encourage the betterment and success of these key services.",
  //   modalheader: "Normal Donation",
  //   PopupHeader: "Normal Donation",
  //   PopupImage: festival7,
  //   PointOne: "1. We Donate a Food At Rural Area",
  //   PointTwo: "2. We Donate a Food At Urban Area",
  //   PointThree: "3. We Donate a food at Natural Disasters",
  //   PointFour: "4. We Donate a food at pilgrimage ",
  //   ParaOne:
  //   "Quality of life comes with proper health and properly educating mind and soul. We are committed to providing our children with the best quality of life.",
  //   ParaTwo:
  //     "All functions carried out in the temple are meant to support and encourage the betterment and success of these key services.",
  // },
];

export default ServiceData;
