import React, { useState} from "react";
import "../Css/About.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Footer from "../Components/Footer";
import PopMail from "../Components/PopMail";
import { Link } from "react-router-dom";
import Founder from "../img/founder.jpg";
import festival from "../img/festival/festival1.jpg";
import prith from "../img/festival/pitra2.jpg";
import festival4 from "../img/festival/festival4.jpg";
import festival7 from "../img/festival/festival7.avif";
import Donate from "../img/festival/donate.jpg";
import Tirth from "../img/festival/trith5.jpg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import VolenterCard from "./VolenterCard";
import VolinterData from "../Components/VolinterData";
import Joshi from '../img/volunteer/joshi.jpg';
import Prakash from '../img/volunteer/prakash.jpg';
import Siddhi from '../img/volunteer/siddhi.jpg';
import Surekha from '../img/volunteer/surekha.jpg';
import Umesh from '../img/volunteer/umesh.jpg';
import { MetaTags } from "react-meta-tags";
const About = () => {

  //  Result  ////
  const [data, setData] = useState(VolinterData);
  const categoryData = VolinterData.map((value) => {
    return value.Category;
  });

  const tabsData = ["all", ...new Set(categoryData)];
  const filterCategory = (Category) => {
    if (Category == "all") {
      setData(VolinterData);
      return;
    }
    const filteredData = VolinterData.filter((value) => {
      return value.Category == Category;
    });

    setData(filteredData);
  };
  return (
    <>
     {/* ========= SEO ========== */}

     <MetaTags>
        <title>
        About Shri Jalaram Bapa Foundation (NGO)
        </title>
        <meta
          name="description"
          content="The JRB foundation is a non-profit organization that collects food donations from individuals, businesses, and other organizations, and distributes."
        />

        <meta
          property="og:title"
          content="About Shri Jalaram Bapa Foundation (NGO)"
        />
        <meta
          property="og:description"
          content="The JRB foundation is a non-profit organization that collects food donations from individuals, businesses, and other organizations, and distributes."
        />
        <meta
          name="keywords"
          content="best place to donate food, food donation, food charity, food drive, charities that donate food, charities for feeding the hungry ,charities feeding the poor, charities that give food to the poor, best charities for feeding the poor, feed the poor, giving food to the needy"
        />
        <link rel="canonical" href="https://jrbannakshetra.com/about-shri-jalaram-bapa-foundation" />
      </MetaTags>
      {/* ======= About Section1 =========== */}
      <div className="aboutBanner">
        <div className="aboutBanner1">
          <Zoom>
            <h1>About Shri Jalaram Bapa Foundation (NGO) </h1>
          </Zoom>
          <Fade left>
            <p>Grain donation is great donation where Gold Silver is Half Donation</p>
          </Fade>
        </div>
      </div>

      {/* about section2 */}
      <div class="about-page-welcome mt-5">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6 order-2 order-lg-1">
              <div class="about-detail">
                <div className="home-event_Header">
                  <h4>
                    About Founder of<span> Shri Jalaram Bapa Foundation</span>
                  </h4>
                </div>
                <p>
                  Raghubar Dutt Tiwari is a spiritual preacher and mentor of
                  Sanatan Dharma, spreading timeless spiritual cults across
                  societies through our various ancient Vedic scriptures and
                  cultures through spiritual discourses organised throughout the
                  year and also rendering medical services since 35 years
                  globally.
                </p>
                <p>
                  {" "}
                  On YouTube (Sri Raghubir Das Bhagwat Katha), various lectures and
                  discourse series on various spiritual topics are available.
                  Our mission is to help people in every possible way in terms
                  of fulfilling their basic needs like food, clothing, and
                  shelter, and thus help them appropriate God's holy names and
                  make them happy. In the same line, we are deeply inspired by a
                  great saint in Gujarat named Sri Jalaram Bapa.
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-6 mt-4 order-1 order-lg-2">
              <div className="founder-img">
                <img src={Founder} alt="raghubir prabhuji" />
              </div>
              {/* <div class="about-img">
                <img src="https://preview.colorlib.com/theme/thecharity/images/welcome.jpg" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* ============== About Section3 =========== */}
        {/* ============== Home Section4 =========== */}
        <div class="home-page-events">
        <div class="container">
          <div className="home-event_Header">
            <h4>
              Our on-going Events in <span>Shri Jalaram Bapa Annakshetra</span>
            </h4>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="upcoming-events">
              <div class="event-wrap d-flex flex-wrap justify-content-between">
                  <figure class="m-0">
                    <img src={Donate} alt="anna daan" />
                  </figure>
                  <div class="event-content-wrap">
                    <header class="entry-header d-flex flex-wrap align-items-center">
                      <h3 class="entry-title w-100 m-0">
                        <a href="#">Food Donation Event</a>
                      </h3>
                      {/* <div class="posted-date">
                        <a href="#">Aug 25, 2018 </a>
                      </div>
                      <div class="cats-links">
                        <a href="#">Ball Room New York</a>
                      </div> */}
                    </header>
                    <div class="entry-content">
                      <p class="m-0">
                        Jalaram Bapa foundation helps to provide emergency food
                        parcels to people in crisis and support thousands of
                        families in communities across the area.
                        {/* jalaram bapa foundation also topped up the donations during the collection with food resources from its own kitchens to ensure more food can get to where it is most needed. */}
                      </p>
                    </div>
                    <div className="event-btn">
                      <a href="/event-shri-jalaram-bapa-foundation">
                        <Link to="/event-shri-jalaram-bapa-foundation">Read More</Link>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="event-wrap d-flex flex-wrap justify-content-between">
                  <figure class="m-0">
                    <img src={prith} alt="ancestor" />
                  </figure>
                  <div class="event-content-wrap">
                    <header class="entry-header d-flex flex-wrap align-items-center">
                      <h3 class="entry-title w-100 m-0">
                        <a href="#">Pitru Paksha Event</a>
                      </h3>
                      {/* <div class="posted-date">
                        <a href="#">Aug 25, 2018 </a>
                      </div>
                      <div class="cats-links">
                        <a href="#">Ball Room New York</a>
                      </div> */}
                    </header>
                    <div class="entry-content">
                      <p class="m-0">
                        Shradh Puja or the Pitru Paksha is conducted to pay
                        homage to the departed and beloved Ancestors.
                        {/* It is believed that the Shradh Pujas are performed with dedication and admiration to help the souls rest in peace making them bless their kin with assurance, peace, and thriving. */}
                      </p>
                    </div>
                    <div className="event-btn">
                      <a href="/event-shri-jalaram-bapa-foundation">
                        <Link to="/event-shri-jalaram-bapa-foundation">Read More</Link>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="event-wrap d-flex flex-wrap justify-content-between">
                  <figure class="m-0">
                    <img src={Tirth} alt="pad yatri" />
                  </figure>
                  <div class="event-content-wrap">
                    <header class="entry-header d-flex flex-wrap align-items-center">
                      <h3 class="entry-title w-100 m-0">
                        <a href="#">Tirth Yatra Event</a>
                      </h3>
                      {/* <div class="posted-date">
                        <a href="#">Aug 25, 2018 </a>
                      </div>
                      <div class="cats-links">
                        <a href="#">Ball Room New York</a>
                      </div> */}
                    </header>
                    <div class="entry-content">
                      <p class="m-0">
                        We organize pilgrimage in a proper manner in which the
                        pilgrims are to be provided good quality food, Satsang
                        programmes, medical facilities and complete information
                        on the places they visit.
                      </p>
                    </div>
                    <div className="event-btn">
                      <a href="/event-shri-jalaram-bapa-foundation">
                        <Link to="/event-shri-jalaram-bapa-foundation">Read More</Link>
                      </a>
                    </div>
                  </div>
                </div>
             
              
            
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="upcoming-events">
              <div class="event-wrap d-flex flex-wrap justify-content-between">
                  <figure class="m-0">
                    <img src={festival7} alt="satnarayan pooja" />
                  </figure>
                  <div class="event-content-wrap">
                    <header class="entry-header d-flex flex-wrap align-items-center">
                      <h3 class="entry-title w-100 m-0">
                        <a href="#">Festival Events</a>
                      </h3>
                      {/* <div class="posted-date">
                        <a href="#">Aug 25, 2018 </a>
                      </div>
                      <div class="cats-links">
                        <a href="#">India </a>
                      </div> */}
                    </header>
                    <div class="entry-content">
                      <p class="m-0">
                        At our foundation we all take part with the teachers and
                        devotees, manage and enjoy these celebrations.
                      </p>
                    </div>
                    <div className="event-btn">
                      <a href="/event-shri-jalaram-bapa-foundation">
                        <Link to="/event-shri-jalaram-bapa-foundation">Read More</Link>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="event-wrap d-flex flex-wrap justify-content-between">
                  <figure class="m-0">
                    <img src={festival} alt="occasion" />
                  </figure>
                  <div class="event-content-wrap">
                    <header class="entry-header d-flex flex-wrap align-items-center">
                      <h3 class="entry-title w-100 m-0">
                        <a href="#">Normal Event</a>
                      </h3>
                      {/* <div class="posted-date">
                        <a href="#">Aug 25, 2018 </a>
                      </div>
                      <div class="cats-links">
                        <a href="#">Ball Room New York</a>
                      </div> */}
                    </header>
                    <div class="entry-content">
                      <p class="m-0">
                        {/* Our festival team brings strategy, creativity and depth of experience. */}
                        All functions carried out in the temple are all meant to
                        support and encourage the betterment and success of
                        these key services.
                      </p>
                    </div>
                    <div className="event-btn">
                      <a href="/event-shri-jalaram-bapa-foundation">
                        <Link to="/event-shri-jalaram-bapa-foundation">Read More</Link>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="event-wrap d-flex flex-wrap justify-content-between">
                  <figure class="m-0">
                    <img src={festival4} alt="mandir pooja" />
                  </figure>
                  <div class="event-content-wrap">
                    <header class="entry-header d-flex flex-wrap align-items-center">
                      <h3 class="entry-title w-100 m-0">
                        <a href="#">Temple Event</a>
                      </h3>
                      {/* <div class="posted-date">
                        <a href="#">Aug 25, 2018 </a>
                      </div>
                      <div class="cats-links">
                        <a href="#">Ball Room New York</a>
                      </div> */}
                    </header>
                    <div class="entry-content">
                      <p class="m-0">
                        Religious, educational, and social activities are
                        conducted at the Jalaram Bapa Foundation .
                        {/* All religious Festivals, according to the Panchang (Hindu lunar calendar), are celebrated at our place regularly.  */}
                      </p>
                    </div>
                    <div className="event-btn">
                      <a href="/event-shri-jalaram-bapa-foundation">
                        <Link to="/event-shri-jalaram-bapa-foundation">Read More</Link>
                      </a>
                    </div>
                  </div>
                </div>
          
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ========== About Section4 ============= */}
      <div className="our-causes">
        <div className="container">
          <div className="home-services_Header">
            <h4>
              What Our <span>People Say</span>
            </h4>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="Swiper"
          >
            <SwiperSlide>
              <div class="testimonal">
                <p>
                  <span>"</span>
                  I endorse FULLY this magnificent endeavour  and sincere effort of Raghubar Das ji (RDJ) in the service of mankind!
                </p>
                <div class="entry-footer">
                  <div class="textimonal-img">
                    <img src={Joshi} alt="deval joshi"/>
                  </div>
                  <h6>
                  Deval Joshi, <span>Volunteer</span>
                  </h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonal">
                <p>
                  <span>"</span>
                  As a responsible person in line of service to Lord Krishna for past 35 years, RDJ has served mankind of all classes in his best of capacity, even if such a need arises at the middle of the night!
                </p>
                <div class="entry-footer">
                  <div class="textimonal-img">
                  <img src={Umesh} alt="umesh tripathi"/>
                  </div>
                  <h6>
                  Umesh Tripathi, <span>Volunteer</span>
                  </h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonal">
                <p>
                  <span>"</span>
                  Coming from a humble background of farmer's family of Uttrakhand, RDJ helped so many people of different classes and background mainly with food & medical help.
                </p>
                <div class="entry-footer">
                  <div class="textimonal-img">
                  <img src={Siddhi} alt="siddhi tandon"/>
                  </div>
                  <h6>
                  Siddhi Tandon, <span>Volunteer</span>
                  </h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonal">
                <p>
                  <span>"</span>
                  He now extended his support to provide food and shelter to needy , poor people in Uttrakhand and then to every part of India.
                </p>
                <div class="entry-footer">
                  <div class="textimonal-img">
                  <img src={Prakash} alt="prakash tiwari"/>
                  </div>
                  <h6>
                  Prakash Tiwari, <span>Volunteer</span>
                  </h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonal">
                <p>
                  <span>"</span>
                  May Lord Krishna blesses him with all the continued success!
                </p>
                <div class="entry-footer">
                  <div class="textimonal-img">
                  <img src={Surekha} alt="surekha chaudhary"/>
                  </div>
                  <h6>
                  Surekha Chaudhary, <span>Volunteer</span>
                  </h6>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      {/* =========== About Section5 ============ */}
      <section class="volunteerSection mt-5">
        <div class="container">
          <div className="home-event_Header">
            <h4>
              Our Esteemed Volunteers @ <span>Shri Jalaram Bapa Foundation</span>
            </h4>
          </div>

          <div className="row">
            <Box className="vol_Tabs">
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {tabsData.map((category, index) => {
                  return (
                    <Tab
                      className="btn btn-outline-primary my-2 mx-3 text-capitalize ButtonG"
                      onClick={() => filterCategory(category)}
                      key={index}
                      label={category}
                    />
                  );
                })}
              </Tabs>
            </Box>
          </div>
            <VolenterCard data={data} />
        </div>
      </section>
      <Footer />
      <PopMail />
    </>
  );
};

export default About;
